import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';

import { EnumValueDisplay, FormCheckbox, FormSelect, FormTextField } from '@lib/common';
import { ListFilters } from 'app/shared';
import { BillingFrequencyType } from '@lib/state/api/generated/reservations';

export interface CorporateAccountFilters {
  name?: string;
  billingFrequencyType?: string;
  showAll?: boolean;
}

interface Props {
  filters: CorporateAccountFilters;
  onChange: (filters: CorporateAccountFilters) => void;
}

export const CorporateAccountSearchFilter: React.FC<Props> = ({ filters, onChange }) => {
  return (
    <ListFilters filters={filters} onChange={onChange}>
      <Grid container spacing={2} style={{ padding: '0 1rem 1rem 1rem', marginTop: '1rem' }}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <FormTextField
            name="name"
            label="Name"
            fullWidth
            size="small"
            data-testid="corporateAccountNameSearchInput"
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <FormSelect name="billingFrequencyType" label="Billing Frequency" fullWidth size="small">
            <MenuItem value={'All'}>All</MenuItem>
            {Object.values(BillingFrequencyType).map(x => (
              <MenuItem key={x} value={x} dense>
                <EnumValueDisplay value={x} disableTypography />
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <FormCheckbox name="showAll" label="Show All" fullWidth />
        </Grid>
      </Grid>
    </ListFilters>
  );
};
