import { from, MonoTypeOperatorFunction } from 'rxjs';
import {
  MaintenanceApi,
  QRCodeApi,
  SubmitGuestMaintenanceTicketRequest,
  SubmitGuestMaintenanceTicketResponse,
} from '../api/generated/properties';
import {
  CreateQRCodeRequest,
  QRCodeFilter,
  SubmitGuestFeedbackRequest,
  UpdateQRCodeRequest,
} from './qr-code.model';
import { QRCodeStore, qrCodeStore } from './qr-code.store';
import { map, switchMap, tap } from 'rxjs/operators';
import { applyTransaction, setLoading } from '@datorama/akita';
import { PaginationState } from '../models.common';
import { dispatchForm } from '../forms';
import { QRCodeForms } from './qr-code.form';
import { AxiosPromise, AxiosResponse } from 'axios';
import { uploadPhoto } from './utils';

export class QRCodeService {
  constructor(
    private store: QRCodeStore,
    private api: QRCodeApi,
    private maintenanceApi: MaintenanceApi
  ) {}

  public createQRCode(propertyId: string, request: CreateQRCodeRequest) {
    from(this.api.propertyIdQrcodePut(propertyId, request))
      .pipe(
        map(data => data.data.data),
        dispatchForm(QRCodeForms.CreateQRCode),
        setLoading(this.store)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.store.upsert(data.id, data);
        })
      );
  }

  public getQRCodeByID(propertyId: string, id: string) {
    from(this.api.propertyIdQrcodeIdGet(id, propertyId))
      .pipe(
        dispatchForm(QRCodeForms.LoadQRCode),
        map(data => data.data.data),
        setLoading(this.store)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.store.upsert(data.id, data);
          this.store.setActive(data.id);
        })
      );
  }

  public getQRCodes(
    { propertyId, type, name, status, roomNumber, location, qrCodeNumber }: QRCodeFilter,
    continuationToken?: string | null,
    limit?: number | null
  ) {
    from(
      this.api.propertyIdQrcodeGet(
        propertyId ?? '',
        continuationToken ?? undefined,
        limit ?? 20,
        type ?? undefined,
        name ?? undefined,
        status ?? undefined,
        roomNumber ?? undefined,
        location ?? undefined,
        qrCodeNumber ?? undefined
      )
    )
      .pipe(
        map(({ data: { data, ...rest }, ...page }) => ({ data, page: { ...rest, ...page } })),
        setLoading(this.store)
      )
      .subscribe(({ data, page }) =>
        applyTransaction(() => {
          this.store.upsertMany(data);
          this.updatePaginationState(page);
          this.updateFilters({
            propertyId,
            type,
            name,
            status,
            roomNumber,
            location,
            qrCodeNumber,
          });
        })
      );
  }

  public updateQRCode(propertyId: string, request: UpdateQRCodeRequest) {
    from(this.api.propertyIdQrcodePost(propertyId, request))
      .pipe(
        map(data => data.data.data),
        dispatchForm(QRCodeForms.UpdateQRCode),
        setLoading(this.store)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.store.update(data.id, data);
        })
      );
  }

  public disableQRCode(propertyId: string, id: string) {
    from(this.api.propertyIdQrcodeIdDelete(id, propertyId))
      .pipe(
        map(data => data.data.data),
        dispatchForm(QRCodeForms.DisableQRCode),
        setLoading(this.store)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.store.update(data.id, data);
        })
      );
  }

  public submitTicket(
    id: string,
    propertyId: string,
    request: SubmitGuestMaintenanceTicketRequest,
    photo?: File
  ) {
    from(this.api.propertyIdQrcodeIdTicketPost(id, propertyId, request))
      .pipe(
        uploadPhoto(this.maintenanceApi, photo),
        map(data => data.data),
        dispatchForm(QRCodeForms.SubmitFeedback)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.store.update({ maintenanceTicketNumber: data.maintenanceTicketNumber });
        })
      );
  }

  public submitFeedback(id: string, propertyId: string, request: SubmitGuestFeedbackRequest) {
    from(this.api.propertyIdQrcodeIdFeedbackPost(id, propertyId, request))
      .pipe(dispatchForm(QRCodeForms.SubmitFeedback))
      .subscribe();
  }

  public resetMaintenanceTicketNumber() {
    this.store.update({ maintenanceTicketNumber: undefined });
  }

  private updatePaginationState(
    { isDone, continuationToken }: PaginationState,
    key: 'pagination' | 'current' | 'future' = 'pagination'
  ) {
    this.store.update(() => ({
      [key]: { isDone, continuationToken },
    }));
  }

  private updateFilters(filters: QRCodeFilter) {
    this.store.update(() => ({
      filters,
    }));
  }
}

export const qrCodeService = new QRCodeService(qrCodeStore, new QRCodeApi(), new MaintenanceApi());
