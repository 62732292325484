import React, { useCallback, useMemo, useState } from 'react';
import { DialogContent, Button, ButtonProps, Typography } from '@material-ui/core';
import { addDays, differenceInDays, isBefore, lightFormat, parseISO, startOfDay } from 'date-fns';
import {
  Reservation,
  reservationService,
  Name,
  Address,
  roomRateQuery,
  roomRateService,
  ReservationForms,
  RoomRateForms,
  ReservationActivity,
  FormStatus,
  getPaymentUserId,
  getPromoCode,
} from '@lib/state';
import { StyledDialog } from '../components';
import { Feedback, FormHub, useFormEvents } from '../forms';
import {
  useObservable,
  getAcceptCardData,
  useLookup,
  useReservationPaymentMethods,
} from '../utils';
import { RebookReservationForm } from './forms/rebook-reservation.form';
import { useBookingRole } from './forms/useBookingRole';

interface FormFields {
  checkInDate: Date;
  checkOutDate: Date;
  billing: {
    name: Name;
    address: Address;
  };
  card?: {
    cardNumber: string;
    expiryDate: string;
    cvc: string;
  };
  overrideCode?: number;
  savePaymentMethod?: boolean;
  paymentProfileId: string;
}

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation: Reservation;
  createForGuest: boolean;
  overrideable?: boolean;
  isManagerOverrideRequired?: boolean;
  rebookOnly?: boolean;
}

const RebookButtonComponent: React.FC<Props> = ({
  reservation,
  createForGuest,
  overrideable = false,
  isManagerOverrideRequired = false,
  rebookOnly = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    roomRateService.resetUI();
    roomRateService.clearUpdateCost(); // This is required since we are using the get availability by reservation
    setOpen(false);
  };

  const { isDelegate, isEmployee } = useBookingRole(
    reservation.affiliation?.corporateAccountId ?? undefined
  );

  const validPolicy = reservation.cancelable;
  const overrideRequired = !validPolicy || isManagerOverrideRequired;

  const rebookLabelText = useMemo(() => {
    return rebookOnly ? 'Rebook' : 'Cancel & Rebook';
  }, [rebookOnly]);

  if (!isDelegate && !isEmployee) return null;

  return (
    <>
      <Button type="button" name="rebookReservation" onClick={() => setOpen(true)} {...props}>
        {rebookLabelText}
      </Button>
      <StyledDialog title={rebookLabelText} open={open} onClose={() => closeDialog()}>
        <DialogContent>
          <RebookReservationFormContainer
            reservation={reservation}
            isManagerOverrideRequired={overrideRequired}
            overrideable={overrideable}
            createForGuest={createForGuest}
            onClose={closeDialog}
            rebookOnly={rebookOnly}
            rebookLabelText={rebookLabelText}
          />
        </DialogContent>
      </StyledDialog>
    </>
  );
};

const RebookReservationFormContainer: React.FC<{
  reservation: Reservation;
  createForGuest: boolean;
  rebookOnly: boolean;
  rebookLabelText: string;
  overrideable?: boolean;
  isManagerOverrideRequired?: boolean;
  onClose?: () => void;
}> = ({
  reservation,
  overrideable,
  isManagerOverrideRequired = false,
  createForGuest,
  rebookOnly,
  rebookLabelText,
  onClose,
}) => {
  const [{ error: availabilityError, status: availabilityStatus }] = useFormEvents(
    RoomRateForms.Availability
  );

  const [{ error: rebookError, status: rebookStatus }] = useFormEvents(
    ReservationForms.RebookReservation
  );

  const today = startOfDay(new Date());

  const { defaultCheckInDate, defaultCheckOutDate } = useMemo(() => {
    const defaultCheckInDate = rebookOnly ? today : null;
    const defaultCheckOutDate =
      rebookOnly && differenceInDays(parseISO(reservation.checkOutDate), today) > 1
        ? parseISO(reservation.checkOutDate)
        : null;

    if (defaultCheckInDate === null || defaultCheckOutDate === null) {
      return { defaultCheckInDate, defaultCheckOutDate };
    }

    if (!reservation) return { defaultCheckInDate, defaultCheckOutDate };

    if (!isBefore(parseISO(reservation.checkInDate), new Date()))
      return { defaultCheckInDate, defaultCheckOutDate };

    return {
      defaultCheckInDate: today,
      defaultCheckOutDate: addDays(
        today,
        defaultCheckOutDate && defaultCheckInDate
          ? differenceInDays(defaultCheckOutDate, defaultCheckInDate)
          : 0
      ),
    };
  }, [reservation]);

  const { paymentMethods, guest } = useReservationPaymentMethods(reservation);
  const roomRates = useObservable(roomRateQuery.roomRates, 'async');
  const roomRateLookup = useLookup(roomRates, x => x.roomTypeId);
  const policy = useObservable(roomRateQuery.policy);

  const onDatesChanged = useCallback(
    (checkInDate: Date, checkOutDate: Date) => {
      if (checkOutDate <= checkInDate) {
        return;
      }
      if (reservation.propertyId && reservation.id) {
        roomRateService.getRoomRatesByReservation(
          reservation.propertyId,
          checkInDate,
          checkOutDate,
          reservation.id,
          reservation.rooms[0].roomTypeId,
          ReservationActivity.ModifyReservation,
          getPromoCode(reservation),
          getPaymentUserId(reservation),
          reservation.affiliation?.corporateAccountId
        );

        reservationService.previewRebookReservation(
          reservation.id,
          checkInDate,
          checkOutDate,
          reservation.rooms.length
        );
      }
    },
    [reservation.id, reservation.rooms.length]
  );

  const onSubmit = useCallback(
    ({
      checkInDate,
      checkOutDate,
      billing,
      savePaymentMethod,
      paymentProfileId,
      card,
      overrideCode,
    }: FormFields) => {
      if (reservation) {
        reservationService.rebookReservation(
          reservation,
          checkInDate,
          checkOutDate,
          reservation.rooms.map(x => ({
            id: x.id,
            roomTypeId: x.roomTypeId,
            dailyRates:
              roomRateLookup.get(x.roomTypeId)?.dailyRates.map(x => ({
                ...x,
                date: lightFormat(x.date, 'yyyy-MM-dd'),
              })) ?? [],
          })),
          billing,
          !!savePaymentMethod,
          paymentProfileId,
          createForGuest,
          rebookOnly,
          getAcceptCardData(card),
          overrideCode
        );
      }
    },
    [reservation, roomRateLookup]
  );

  return (
    <FormHub onSubmit={onSubmit}>
      <Feedback show={rebookStatus === FormStatus.Success} severity="success" onHide={onClose}>
        <Typography align="center" data-testid="rebookSuccessText">
          The reservation has been rebooked successfully.
        </Typography>
      </Feedback>
      <RebookReservationForm
        reservation={reservation}
        paymentMethods={paymentMethods}
        onDateChange={onDatesChanged}
        rebookStatus={rebookStatus}
        rebookError={rebookError}
        availabilityError={availabilityError}
        availabilityStatus={availabilityStatus}
        paymentGuest={guest}
        isManagerOverrideRequired={isManagerOverrideRequired}
        overrideable={overrideable}
        rebookOnly={rebookOnly}
        rebookLabelText={rebookLabelText}
        defaultCheckInDate={defaultCheckInDate}
        defaultCheckOutDate={defaultCheckOutDate}
        policy={policy}
        onClose={onClose}
      />
    </FormHub>
  );
};

export const RebookButton = RebookButtonComponent;
