import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import {
  isEmployeeBookingAllowed,
  RefundReservationChargeModel,
  Reservation,
  reservationService,
  roomTypeQuery,
  roomTypeService,
} from '@lib/state';
import {
  CancelReservationButton,
  CheckInReservationButton,
  CheckOutReservationButton,
  EditReservationButton,
  LoadingIndicator,
  Section,
  useLookup,
  useObservable,
} from '@lib/common';
import {
  corporateAccountQuery,
  corporateAccountService,
  guestStaysQuery,
  guestStaysService,
  journalEntryQuery,
  journalEntryService,
  useGuestNotes,
} from 'app/state';
import { AuditLogs, GuestNotesList } from 'app/shared';
import { ReservationChargesTable } from './reservation-charges-table';
import { ReservationRoomBreakdown } from './reservation-room-breakdown';
import { ReservationInfo } from './reservation-info';
import { PrintableReservationReceiptButton } from './printable-reservation-receipt-button';
import { ChangeBillingButton, ManagementRebookButton, ReservationPolicyDisplay } from '.';
import { ResendUserKeyButton } from './resend-user-key-button';

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    dense: {
      lineHeight: 1,
    },
  })
);

interface Props {
  reservation?: Reservation;
  createForGuest: boolean;
}

export const ReservationDetails: React.FC<Props> = ({ reservation, createForGuest }) => {
  const styles = useStyles();
  const history = useHistory();

  const roomTypes = useObservable(roomTypeQuery.roomTypes);
  const accounts = useObservable(journalEntryQuery.accounts);

  const reservationGuestStays = useObservable(guestStaysQuery.reservationGuestStays);

  const corporateBooking = useObservable(corporateAccountQuery.activeAccount, 'async');
  useEffect(() => {
    if (reservation?.affiliation?.corporateAccountId) {
      corporateAccountService.getCorporateAccountById(reservation?.affiliation?.corporateAccountId);
    }
    return () => corporateAccountService.selectCorporateAccount();
  }, [reservation]);

  const isManagerOverrideRequired = useMemo(
    () => !isEmployeeBookingAllowed(corporateBooking, reservation?.checkOutDate),
    [corporateBooking, reservation]
  );

  useEffect(() => {
    if (reservation?.propertyId) {
      journalEntryService.getAccounts(reservation?.propertyId);
      guestStaysService.getGuestStaysByReservation(reservation?.propertyId, reservation?.id);
    }
    if (reservation?.id) {
      reservationService.getReservationPolicy(reservation.id);
    }
  }, [reservation]);

  const roomTypeIds = useMemo(() => reservation?.rooms.map(x => x.roomTypeId), [reservation]);
  useEffect(() => {
    if (!roomTypeIds) return;

    roomTypeService.getRoomTypes(...roomTypeIds);
  }, [roomTypeIds]);

  const roomTypeLookup = useLookup(roomTypes, x => x.id);

  const handleGuestCheckIn = useCallback(
    (res: Reservation) => {
      history.push(`/kiosk/front-desk/${res.id}/account`);
    },
    [history]
  );

  const refundCharge = (model: RefundReservationChargeModel) => {
    if (reservation?.id) reservationService.refundCharge(reservation.id, model);
  };

  const notes = useGuestNotes({ propertyId: reservation?.propertyId, guests: reservation?.userId });

  if (!reservation) return <LoadingIndicator loadingText="Loading Reservation" fillContainer />;

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        flexDirection="row"
        marginBottom={2}
        data-testid="reservationDetails"
      >
        {reservation.status === 'CheckedIn' && (
          <ResendUserKeyButton reservation={reservation} className={styles.button} />
        )}
        <PrintableReservationReceiptButton
          className={styles.button}
          reservation={reservation}
          charges={reservation.charges}
          accounts={accounts}
          refundCharge={refundCharge}
          data-testid="reservationDetailsPrintButton"
        />
        <CancelReservationButton
          className={styles.button}
          reservation={reservation}
          variant="contained"
          color="secondary"
          overrideable
          data-testid="reservationDetailsCancelButton"
        />
        <EditReservationButton
          className={styles.button}
          reservation={reservation}
          color="secondary"
          variant="contained"
          isManagerOverrideRequired={isManagerOverrideRequired}
          data-testid="reservationDetailsEditButton"
        />
        <ManagementRebookButton
          className={styles.button}
          reservation={reservation}
          color="secondary"
          variant="contained"
          isManagerOverrideRequired={isManagerOverrideRequired}
          createForGuest={createForGuest}
          data-testid="reservationDetailsRebookButton"
        />
        <ChangeBillingButton
          className={styles.button}
          reservation={reservation}
          color="secondary"
          variant="contained"
          data-testid="reservationDetailsChangeBillingButton"
        />
        <CheckInReservationButton
          className={styles.button}
          reservation={reservation}
          variant="contained"
          color="secondary"
          onClick={() => handleGuestCheckIn(reservation)}
          data-testid="reservationDetailsCheckInButton"
        />
        <CheckOutReservationButton
          reservation={reservation}
          className={styles.button}
          color="secondary"
          variant="contained"
          overrideable={true}
          data-testid="reservationDetailsCheckOutButton"
        />
      </Box>
      <ReservationInfo reservation={reservation} guestStays={reservationGuestStays} />
      <Section title="Charges" disableGutters>
        <ReservationChargesTable
          reservation={reservation}
          charges={reservation.charges}
          accounts={accounts}
          refundCharge={refundCharge}
        />
      </Section>
      <Section title="Rooms" disableGutters data-testid="reservationRoomBreakdownsSection">
        {reservation.rooms.map(room => (
          <ReservationRoomBreakdown
            reservation={reservation}
            key={room.id}
            room={room}
            fees={reservation.fees.filter(f => f.reservationRoomId === room.id)}
            checkOutDate={reservation.checkOutDate}
            roomType={roomTypeLookup.get(room.roomTypeId)}
            charges={reservation.charges}
            accounts={accounts}
            refundCharge={refundCharge}
          />
        ))}
      </Section>
      {reservation &&
        reservation.contacts &&
        reservation.contacts[0] &&
        reservation.contacts[0].userId && (
          <Section title="Notes" disableGutters>
            <GuestNotesList
              propertyId={reservation.propertyId}
              guestUserId={reservation.contacts[0].userId}
              guestStayId={
                reservationGuestStays && reservationGuestStays.length > 0
                  ? reservationGuestStays[0].id
                  : undefined
              }
              notes={notes}
            />
          </Section>
        )}
      {reservation.policy && (
        <Section title="Policies">
          <ReservationPolicyDisplay policy={reservation.policy} />
        </Section>
      )}
      <Section title="Audit Logs" disableGutters>
        <AuditLogs scope="reservation" id={reservation.id} />
      </Section>
    </>
  );
};
