import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import {
  useFormEvents,
  LoadingIndicator,
  ErrorDisplay,
  SubmitButton,
  FormCurrencyField,
  FormSelect,
  FormTextField,
  Feedback,
  CurrencyDisplay,
  FormCheckbox,
} from '@lib/common';
import {
  ReservationCharge,
  TaxRates,
  ReservationForms,
  FormStatus,
  Reservation,
  TaxAmounts,
} from '@lib/state';
import {
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from '@material-ui/core';
import { JournalAccount } from 'app/state';

interface Props {
  reservation: Reservation;
  charge: ReservationCharge;
  accounts: JournalAccount[];
  isPartialRefund?: boolean;
  amountToRefund?: number;
}

interface RefundAccount {
  account: string;
  amount?: string;
}

export interface RefundForm {
  chargeId: string;
  description: string;
  refundAccounts: RefundAccount[];
  taxAccounts: RefundAccount[];
}

function parseLineItems(lineItems: RefundAccount[] | undefined) {
  const accounts =
    lineItems?.map(({ account, amount }) => {
      const parsedAmount = parseFloat(amount || '0');
      return { account, valid: !isNaN(parsedAmount), input: amount, amount: parsedAmount };
    }) ?? [];

  const total = accounts.reduce((sum, account) => sum + (account.amount ?? 0), 0);

  return { accounts, total };
}

const taxAccounts: Array<{ key: keyof TaxRates; account: string }> = [
  { key: 'state', account: '2130' },
  { key: 'county', account: '2140' },
  { key: 'city', account: '2150' },
];

function calculateTax(refundSubTotal: number, tax: TaxAmounts) {
  const rates = tax.rates ?? ({} as TaxRates);

  return taxAccounts
    .map(({ key, account }) => ({ rate: rates[key], account }))
    .map(
      ({ rate, account }) =>
        ({ account, amount: ((refundSubTotal * (rate ?? 0)) / 100).toFixed(2) } as RefundAccount)
    );
}

const ReservationRefundFormComponent: React.FC<Props> = ({
  reservation,
  charge,
  accounts,
  isPartialRefund,
  amountToRefund,
}) => {
  const tax = useMemo(() => {
    return reservation.rooms[0].rates[0].tax;
  }, [reservation]);

  const [{ error, status }] = useFormEvents(ReservationForms.RefundReservationCharge);

  const { register, setValue, watch } = useFormContext();
  const { fields: refundFields, append: appendRefund } = useFieldArray({
    name: 'refundAccounts',
  });
  const { fields: taxFields, append: appendTax } = useFieldArray({
    name: 'taxAccounts',
  });

  useEffect(() => {
    register({ name: 'chargeId' }, { required: true });
    setValue('chargeId', charge.id);
  }, [charge, register, setValue]);

  useEffect(() => {
    const refunds: Array<RefundAccount> = [];

    for (let i = 0; i < 5; i++) {
      refunds.push({ account: '', amount: '' });
    }

    appendRefund(refunds);
  }, [appendRefund]);

  useEffect(() => {
    const taxes = calculateTax(0, tax);
    appendTax(taxes);
  }, [appendTax, tax]);

  const watched = watch(['refundAccounts', 'taxAccounts', 'calculateTax']);
  console.debug('WATCHED', watched);

  const { accounts: refundLineItems, total: refundTotal } = parseLineItems(watched.refundAccounts);
  const { accounts: taxLineItems, total: taxTotal } = parseLineItems(watched.taxAccounts);

  const calculateTaxEnabled = watched.calculateTax as boolean;

  useEffect(() => {
    if (calculateTaxEnabled) {
      // calculate tax accounts and amounts
      const taxes = calculateTax(refundTotal, tax);
      setValue('taxAccounts', taxes);
    } else {
      // deselect all tax accounts and clear amounts
      const taxes = taxAccounts.map(() => ({ account: null, amount: null }));
      setValue('taxAccounts', taxes);
    }
  }, [setValue, charge, refundTotal, calculateTaxEnabled, tax]);

  const validate = () => {
    if (refundTotal > charge.refundableAmount)
      return 'Refund total cannot be greater than the remaining refundable amount';
    if (refundLineItems.some(x => !x.valid)) return 'Refund amounts must be valid currency amounts';
    if (refundLineItems.some(x => x.amount != null && x.amount < 0))
      return 'Refund amounts cannot be negative';
    if (refundLineItems.some(x => x.amount > 0 && !x.account))
      return 'Refund accounts must be specified for all entered amounts';

    const specifiedAccounts = [
      ...refundLineItems.filter(i => !!i.amount).map(i => i.account),
      ...taxLineItems.filter(i => !!i.amount).map(i => i.account),
    ];
    if (specifiedAccounts.length === 0 || refundTotal <= 0)
      return 'At least one refund account and amount must be specified';

    const distinctAccounts = new Set<string>(specifiedAccounts);
    if (specifiedAccounts.length > distinctAccounts.size)
      return 'Duplicate accounts must not be specified';

    return null;
  };

  const validationResult = validate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormTextField
          type="text"
          name="description"
          label="Refund Description"
          fullWidth
          validationOptions={{ maxLength: 200 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Refund Accounts</Typography>

        <FormCheckbox name="calculateTax" label="Calculate Taxes Automatically" />

        <Feedback show={!!validationResult} severity="error">
          {validationResult}
        </Feedback>

        <Table size="small" padding="none">
          <RefundLineItems
            accounts={accounts}
            lineItems={refundFields}
            groupName="refundAccounts"
            showAccount={account =>
              !(calculateTaxEnabled && taxAccounts.some(t => t.account === account))
            }
            amountToRefund={amountToRefund}
            isPartialRefund={isPartialRefund}
          />
          {calculateTaxEnabled && (
            <RefundLineItems
              accounts={accounts}
              lineItems={taxFields}
              groupName="taxAccounts"
              readonly
              showAccount={account => taxAccounts.some(t => t.account === account)}
            />
          )}
          <TableFooter>
            <TableRow>
              <TableCell>
                <Typography align="right">Total</Typography>
              </TableCell>
              <TableCell>
                <Typography align="right">
                  <CurrencyDisplay
                    value={(refundTotal ?? 0) + (!isPartialRefund ? taxTotal ?? 0 : 0)}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <ErrorDisplay error={error} />
        {status === FormStatus.Pending ? <LoadingIndicator loadingText="Processing..." /> : null}
        <SubmitButton
          disabled={!!validationResult}
          style={{ display: 'block' }}
          variant="contained"
          color="secondary"
        >
          Refund
        </SubmitButton>
      </Grid>
    </Grid>
  );
};

const RefundLineItems: React.FC<{
  groupName: string;
  amountToRefund?: number;
  isPartialRefund?: boolean;
  accounts: Array<{ accountNumber: string; label: string }>;
  showAccount(account: string): boolean;
  lineItems?: Array<{ id?: string } & Record<string, any>>;
  readonly?: boolean;
}> = ({
  accounts,
  lineItems,
  groupName,
  readonly = false,
  showAccount,
  amountToRefund,
  isPartialRefund,
}) => {
  console.debug('LINE ITEMS', groupName, lineItems);

  if (!lineItems) return null;

  if (isPartialRefund) {
    var lineItem: Array<{ id?: string } & Record<string, any>> | undefined;
    lineItem = [];
    if (lineItems[0] !== undefined) {
      lineItem.push(lineItems[0]);
    }
    return (
      <TableBody>
        {lineItem.map((item, idx) => (
          <TableRow key={item.id}>
            <TableCell>
              <FormSelect
                name={`${groupName}[${idx}].account`}
                label="Account"
                fullWidth
                disabled={readonly}
                defaultValue={item.account}
              >
                <MenuItem value={''}>None</MenuItem>
                {accounts.map(
                  ({ accountNumber: account, label }) =>
                    account &&
                    showAccount(account) && (
                      <MenuItem key={account} value={account}>
                        {label}
                      </MenuItem>
                    )
                )}
              </FormSelect>
            </TableCell>
            <TableCell align="right" width={140}>
              <FormCurrencyField
                name={`${groupName}[${idx}].amount`}
                label="Amount"
                fullWidth
                disabled={readonly || isPartialRefund}
                defaultValue={Number(amountToRefund).toFixed(2)}
                validationOptions={{
                  min: {
                    value: 0,
                    message: 'Value cannot be less than zero',
                  },
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }
  return (
    <TableBody>
      {lineItems.map((item, idx) => (
        <TableRow key={item.id}>
          <TableCell>
            <FormSelect
              name={`${groupName}[${idx}].account`}
              label="Account"
              fullWidth
              disabled={readonly}
              defaultValue={item.account}
            >
              <MenuItem value={''}>None</MenuItem>
              {accounts.map(
                ({ accountNumber: account, label }) =>
                  account &&
                  showAccount(account) && (
                    <MenuItem key={account} value={account}>
                      {label}
                    </MenuItem>
                  )
              )}
            </FormSelect>
          </TableCell>
          <TableCell align="right" width={140}>
            <FormCurrencyField
              name={`${groupName}[${idx}].amount`}
              label="Amount"
              fullWidth
              disabled={readonly}
              defaultValue={item.amount}
              validationOptions={{
                min: {
                  value: 0,
                  message: 'Value cannot be less than zero',
                },
              }}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export const ReservationRefundForm = ReservationRefundFormComponent;
