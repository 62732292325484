import { QueryEntity } from '@datorama/akita';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import isEqual from 'lodash.isequal';

import { RoomType } from './room-type.model';
import { RoomTypeState, roomTypeStore } from './room-type.store';

export class RoomTypeQuery extends QueryEntity<RoomTypeState> {
  roomTypes = this.selectAll();

  propertyRoomTypes = this.select(({ ui }) => ui).pipe(
    switchMap(({ propertyId }) =>
      this.selectAll({
        filterBy: x => x.propertyId === propertyId,
        sortBy: (a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0),
      })
    )
  );

  activeRoomType = this.selectActive().pipe(distinctUntilChanged<RoomType>(isEqual));
}

export const roomTypeQuery = new RoomTypeQuery(roomTypeStore);
