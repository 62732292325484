import { AxiosResponse } from 'axios';
import { MonoTypeOperatorFunction, from } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { MaintenanceApi, SubmitGuestMaintenanceTicketResponse } from '../api/generated/properties';

export function uploadPhoto(
  maintenanceApi: MaintenanceApi,
  photo?: File
): MonoTypeOperatorFunction<AxiosResponse<SubmitGuestMaintenanceTicketResponse>> {
  if (photo)
    return obs =>
      obs.pipe(
        switchMap(data =>
          from(
            maintenanceApi.maintenanceIdPhotoUploadPost(data.data.maintenanceTicketId ?? '', photo)
          ).pipe(map(_ => data))
        )
      );

  return obs => obs.pipe();
}
