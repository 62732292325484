import React, { useEffect, useMemo } from 'react';
import { Box, createStyles, Divider, Grid, makeStyles } from '@material-ui/core';

import {
  Reservation,
  FormStatus,
  AvailablePaymentMethods,
  GuestProfile,
  Fee,
  reservationService,
  propertyQuery,
  roomTypeQuery,
  PropertyPolicy,
  RoomRate,
  getPromoCode,
  RoomType,
  roomTypeService,
  ReservationStatus,
} from '@lib/state';
import {
  ErrorDisplay,
  LineItemDisplay,
  PaymentInfoForm,
  SubmitButton,
  withAuthorizeNet,
  RoomRateForm,
  useObservable,
  Section,
} from '@lib/common';
import { useFormContext } from 'react-hook-form';
import { parseISO } from 'date-fns';
import clsx from 'clsx';
import { ChangeDelegateLookupForm } from '.';

const useStyles = makeStyles(() =>
  createStyles({
    relative: {
      position: 'relative',
    },
  })
);

interface Props {
  reservation: Reservation;
  paymentMethods: AvailablePaymentMethods;
  paymentGuest?: GuestProfile;

  status: FormStatus;
  error?: Error;

  availabilityStatus: FormStatus;
  availabilityError?: Error;

  roomRates: Array<RoomRate>;
  policy?: PropertyPolicy;

  onChangeBookingGuest: (guest?: GuestProfile, corporateAccountId?: string) => void;
}

const ChangeBillingFormComponent: React.FC<Props> = ({
  reservation,
  paymentMethods,
  paymentGuest,
  status,
  error,
  availabilityStatus,
  availabilityError,
  roomRates,
  policy,
  onChangeBookingGuest,
}) => {
  const { setValue, register, watch } = useFormContext();
  const { relative } = useStyles();

  const { propertyId, checkInDate, checkOutDate } = watch();

  useEffect(() => {
    reservationService.getReservationChangeBillingOptions(reservation.id);
  }, [reservation.id]);

  useEffect(() => {
    register({ name: 'propertyId' }, { required: true });
    register({ name: 'checkInDate' }, { required: true });
    register({ name: 'checkOutDate' }, { required: true });
    register({ name: 'quantity' }, { required: true, min: 1 });
    register({ name: 'flexRate' });
    register({ name: 'dailyHousekeeping' });
  }, [register]);

  useEffect(() => {
    roomTypeService.getPropertyRoomTypes(reservation.propertyId);
  }, [reservation.propertyId]);

  useEffect(() => {
    setValue('propertyId', reservation.propertyId);
    setValue('checkInDate', parseISO(reservation.checkInDate));
    setValue('checkOutDate', parseISO(reservation.checkOutDate));
    setValue('quantity', reservation.rooms.length);
  }, [
    setValue,
    reservation.propertyId,
    reservation.checkInDate,
    reservation.checkOutDate,
    reservation.rooms.length,
  ]);

  const roomRate = useMemo(() => {
    var roomRate = roomRates[0];
    var selectedCodes = reservation.amenities?.map(amenity => amenity.code) ?? [];

    const amenities = roomRate.amenities.map(amenity => {
      return { ...amenity, selected: selectedCodes.includes(amenity.code) };
    });

    return [{ ...roomRate, amenities }];
  }, [roomRates, reservation.amenities]);

  const properties = useObservable(propertyQuery.openProperties);
  const roomTypes = useObservable(roomTypeQuery.propertyRoomTypes);

  const { previousCharge, previousRefund, newRefund } = useMemo(() => {
    const totals: {
      previousCharge: number;
      previousRefund: number;
      newRefund: number;
      newCharges?: Fee[];
    } = {
      previousCharge: 0,
      previousRefund: 0,
      newRefund: 0,
      newCharges: undefined,
    };

    if (!reservation.reservationBillingCharges?.length) return totals;

    totals.previousCharge = reservation.reservationBillingCharges
      .filter(charge => charge.totalAmount > 0)
      .reduce(function (total, charge) {
        return total + charge.totalAmount;
      }, 0);

    totals.previousRefund = reservation.reservationBillingCharges
      .filter(charge => charge.totalAmount < 0)
      .reduce(function (total, charge) {
        return total + charge.totalAmount;
      }, 0);

    // totals.previousRefund is negative
    totals.newRefund = totals.previousCharge + totals.previousRefund;

    return totals;
  }, [reservation]);

  if (!propertyId || !checkInDate || !checkOutDate) return null;

  return (
    <>
      <Section className={clsx(relative)} title="Refunds" maxWidth="md" variant="contained">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <React.Fragment>
              <LineItemDisplay
                variant="body2"
                label="Total Previous Charges"
                amount={previousCharge}
              />
            </React.Fragment>
            <React.Fragment>
              <LineItemDisplay
                variant="body2"
                label="Total Previous Refunds"
                amount={previousRefund}
              />
              <Box my={0.5}>
                <Divider />
              </Box>
            </React.Fragment>

            <React.Fragment>
              <LineItemDisplay
                variant="subtitle1"
                label="Total Remaining Refund"
                amount={newRefund}
              />
              <Box my={0.5}>
                <Divider />
              </Box>
            </React.Fragment>
          </Grid>
        </Grid>
      </Section>
      <ChangeDelegateLookupForm
        reservation={reservation}
        onChangeBookingGuest={onChangeBookingGuest}
      />

      <RoomRateForm
        properties={properties}
        roomTypes={roomTypes}
        roomRates={roomRate}
        promo={getPromoCode(reservation)}
        policy={policy}
        variant="contained"
        onEditLocation={() => {}}
        setRequiresPayment={() => {}}
        status={FormStatus.Success}
        quantity={reservation.rooms.length}
        selectRoomType={(roomType: RoomType) => {}}
        enabledFeatures={{
          selectRoomType: false,
          changeDates: false,
          changeRoomQuantity: false,
          addAmenities: reservation.status !== ReservationStatus.Canceled,
          addFlexibility: reservation.status !== ReservationStatus.Canceled,
          allowPromoCode: reservation.status !== ReservationStatus.Canceled,
        }}
        reservation={reservation}
      />
      <Section
        className={clsx(relative)}
        title="Select New Payment Method"
        maxWidth="md"
        variant="contained"
      >
        {!!paymentGuest && <PaymentInfoForm paymentMethods={paymentMethods} guest={paymentGuest} />}
      </Section>
      <Box my={3}>
        <ErrorDisplay error={availabilityError ?? error} />
        <SubmitButton
          variant="contained"
          color="secondary"
          fullWidth
          pending={status === FormStatus.Pending || availabilityStatus === FormStatus.Pending}
          disabled={
            availabilityStatus === FormStatus.Pending ||
            availabilityStatus === FormStatus.Error ||
            status === FormStatus.Pending
          }
          data-testid="changeBillingSubmit"
        >
          Confirm New Billing
        </SubmitButton>
      </Box>
    </>
  );
};

export const ChangeBillingForm = withAuthorizeNet(ChangeBillingFormComponent);
