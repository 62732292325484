import { EntityStore, StoreConfig, EntityState, ActiveState } from '@datorama/akita';

import { PaginationState } from '@lib/state';
import { CorporateAccount } from './corporate-account.model';

export interface CorporateAccountUIState {
  name?: string;
  billingFrequencyType?: string;
  showAll?: boolean;
}

export interface CorporateAccountState
  extends EntityState<CorporateAccount, CorporateAccount['id']>,
    ActiveState<CorporateAccount['id']> {
  ui: CorporateAccountUIState;
  pagination: PaginationState;
}

@StoreConfig({ name: 'corporate-account', resettable: true })
export class CorporateAccountStore extends EntityStore<CorporateAccountState> {
  constructor() {
    super({
      ui: {
        name: '',
        billingFrequencyType: 'All',
      },
      pagination: {
        isDone: true,
        continuationToken: undefined,
      },
    });
  }
}

export const corporateAccountStore = new CorporateAccountStore();
