import { applyTransaction } from '@datorama/akita';
import { AxiosPromise } from 'axios';
import { from, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, toArray } from 'rxjs/operators';

import { Auth, getError, Reservations } from '../api';
import { dispatchForm } from '../forms';
import { guestProfileService, GuestProfileService } from '../guest-profile';
import { paymentMethodService, PaymentMethodService } from '../payment-method';
import {
  ReservationForms,
  reservationMapper,
  reservationStore,
  ReservationStore,
} from '../reservation';
import { roomRateService, RoomRateService } from '../room-rate';
import { sessionService, SessionService } from '../session';
import { AcceptCardData, getE164FormattedPhoneNumber, getPaymentNonce, throwIf } from '../utils';
import {
  BookingRequest,
  BookingResult,
  canPreSavePayment,
  mapBookingRequest,
  mapBookingResponse,
} from './booking.model';

export class BookingService {
  constructor(
    private readonly store: ReservationStore,
    private readonly sessionService: SessionService,
    private readonly paymentMethodService: PaymentMethodService,
    private readonly roomRateService: RoomRateService,
    private readonly guestProfileService: GuestProfileService,
    private readonly reservationApi: Reservations.ReservationApi,
    private readonly guestApi: Reservations.GuestApi,
    private readonly accountApi: Auth.AccountApi
  ) {}

  createAccountAndReservation(password: string, request: BookingRequest) {
    const { name, address, contact } = request.contact;

    from(
      this.accountApi.accountCreatePost({
        email: contact.email!,
        phoneNumber: getE164FormattedPhoneNumber(contact.phone),
        password,
      })
    )
      .pipe(
        switchMap(() => this.sessionService.login(contact.email!, password)),
        switchMap(() =>
          this.guestApi.guestProfilePost({
            name,
            address: address!,
          })
        ),
        switchMap(() =>
          this.createReservationObs(
            request,
            this.reservationApi.reservationPost.bind(this.reservationApi),
            true // can skipAccountCheck because the account was just created
          )
        ),
        dispatchForm(ReservationForms.CreateReservation)
      )
      .subscribe();
  }

  createReservation(request: BookingRequest) {
    from(
      this.createReservationObs(
        request,
        this.reservationApi.reservationPost.bind(this.reservationApi)
      )
    )
      .pipe(dispatchForm(ReservationForms.CreateReservation))
      .subscribe();
  }

  createReservationForGuest(request: BookingRequest) {
    from(
      this.createReservationObs(
        request,
        this.reservationApi.reservationManagerPost.bind(this.reservationApi)
      )
    )
      .pipe(dispatchForm(ReservationForms.CreateReservation))
      .subscribe();
  }

  private createReservationObs(
    { card, ...request }: BookingRequest,
    reservationApi: (
      request: Reservations.CreateReservationRequest
    ) => AxiosPromise<Reservations.CreateReservationResponse>,
    skipAccountCheck?: boolean
  ) {
    const { propertyId, checkInDate, checkOutDate } = request;

    // there are now 3 options for booking:
    //  A. Book a single reservation with 1 room (quantity = 1)
    //  B. Book a single reservation with >1 room (quantity > 1)
    //  C. Book multiple reservations each with 1 room (quantity > 1)
    // .. for now, we'll only leave options A (hasMultipleBookings = false) and C (hasMultipleBookings = true) open
    const {
      rooms,
      multipleBooking = true,
      contact: { userId }, // userId is provided through the request for rate lookups, but the backend currently ignores it
      promoCode,
    } = request;
    // the rooms array length does not get updated when the quantity is descreased, only increased.
    // the room objects in the array do get updated. Valid rooms with be assigned a `group` number.
    // filtering out all room objects without a group will produce the correct number of requested rooms.
    const roomsToBook = rooms.filter(x => !!x.group);
    const hasMultipleBookings = roomsToBook.length > 1 && multipleBooking;
    const roomTypes = [...new Set(roomsToBook.map(r => r.rate.roomTypeId))];

    request.savePaymentMethod = true; //Hardcoding to true

    // for multiple bookings, save payment method before creating reservation request
    const preSavePayment = canPreSavePayment(
      request.billing,
      true, //Hardcoding to true
      hasMultipleBookings
    )
      ? this.paymentMethodService.addPaymentMethodObs(
          request?.billing?.name!,
          request?.billing?.address!,
          card
        )
      : of(null);

    // need to verify that the user didn't enter in another email
    const checkIfAccountExists = !!skipAccountCheck
      ? of(null)
      : this.accountApi.accountExistsPost({ email: request.contact.contact.email ?? '', userId });

    // split request into multiple requests based on the # of guests
    const requests = mapBookingRequest(request);
    return from(
      this.roomRateService.availabilityRatesObs(
        propertyId,
        checkInDate,
        checkOutDate,
        roomsToBook.length,
        promoCode,
        userId,
        request.corporateAccountId
      )
    ).pipe(
      throwIf(
        (
          { data: { data } } // check that all of the requested room types are available
        ) => !roomTypes.every(roomTypeId => data.some(rate => rate.roomTypeId === roomTypeId)),
        'Requested number of rooms are no longer available.'
      ),
      switchMap(_ => checkIfAccountExists),
      switchMap(_ => preSavePayment),
      switchMap(_ => this.createMultipleReservationObs(card, requests, reservationApi)),
      map(results => mapBookingResponse(results)),
      tap(({ failures, reservations, consumedHolds }) => {
        //if no reservations were created, then throw first failure error
        if (reservations.length === 0)
          throw failures[0]?.error ?? new Error('Error booking reservation');

        applyTransaction(() => {
          this.store.upsertMany(reservations);
          this.store.update(() => ({
            bookingStatus: { failures, reservations, consumedHolds },
          }));
        });
      }),
      tap(() => this.guestProfileService.reset())
    );
  }

  private createMultipleReservationObs(
    card: AcceptCardData | undefined,
    requests: Reservations.CreateReservationRequest[],
    reservationApi: (
      request: Reservations.CreateReservationRequest
    ) => AxiosPromise<Reservations.CreateReservationResponse>
  ) {
    return from(requests).pipe(
      concatMap(request =>
        getPaymentNonce(card).pipe(
          switchMap(nonce => {
            return reservationApi({
              ...request,
              paymentNonce: nonce?.opaqueData,
            });
          }),
          map(({ data: { data, reservationHolds } }) => {
            return {
              failed: null,
              reservation: reservationMapper()(data),
              consumedHolds: reservationHolds,
            } as BookingResult;
          }),
          catchError((e: Error) => {
            return of({
              reservation: null,
              failed: {
                error: getError(e),
                guest:
                  request.guests?.length > 0
                    ? request.guests[0]
                    : {
                        ...request.contact,
                      },
              },
            } as BookingResult);
          })
        )
      ),
      toArray()
    );
  }
}

export const bookingService = new BookingService(
  reservationStore,
  sessionService,
  paymentMethodService,
  roomRateService,
  guestProfileService,
  new Reservations.ReservationApi(),
  new Reservations.GuestApi(),
  new Auth.AccountApi()
);
