import {
  IDataTableColumn,
  DataTable,
  ShowMoreButton,
  EnumValueDisplay,
  RemoveButton,
  useFormEvents,
} from '@lib/common';
import { QRCode, QRCodeForms, QRCodeStatus, QRCodeType, Room, qrCodeService } from '@lib/state';
import { Typography, Link } from '@material-ui/core';
import { useTableStyles } from 'app/shared';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { EditQRCodeModal } from './edit-qr-code-modal';

interface Props {
  propertyId: string;
  qrCodes: Array<QRCode>;
  rooms: Array<Room>;
  loading: boolean;
  isDone: boolean;
  loadMore: () => void;
}

export function QRCodeList({ propertyId, qrCodes, rooms, loading, isDone, loadMore }: Props) {
  const tableStyles = useTableStyles();
  const [{ status, error }, resetStatus] = useFormEvents(QRCodeForms.DisableQRCode);

  const [selectedCode, setCode] = useState<QRCode>();
  const [editOpen, setEditOpen] = useState(false);

  const disableQRCode = useCallback(
    (qrCode: QRCode) => qrCodeService.disableQRCode(propertyId, qrCode.id),
    [propertyId]
  );
  // when the qrCodes list changes, reset so that the remove button can be used again
  useEffect(() => resetStatus(), [qrCodes, resetStatus]);

  const onEditClick = useCallback(
    (code: QRCode) => {
      setCode(code);
      setEditOpen(true);
    },
    [setCode, setEditOpen]
  );

  const onClose = useCallback(() => {
    setCode(undefined);
    setEditOpen(false);
  }, [setCode, setEditOpen]);

  const renderButtons = useCallback(
    (qrCode: QRCode) => (
      <>
        {qrCode.status === QRCodeStatus.Active && (
          <RemoveButton
            onConfirm={() => disableQRCode(qrCode)}
            status={status}
            error={error}
            action="Disable"
            confirmView={
              <Typography>
                Are you sure you would like to disable the QR code {qrCode?.qrCodeNumber} -{' '}
                {qrCode?.name}?
              </Typography>
            }
          />
        )}
      </>
    ),
    [disableQRCode, status, error]
  );

  const renderEditLink = useCallback(
    (qrCode: QRCode) => <Link onClick={() => onEditClick(qrCode)}>{qrCode.qrCodeNumber}</Link>,
    [onEditClick]
  );

  const renderEnumValue = useCallback(
    (type: QRCodeType) => <EnumValueDisplay className={tableStyles.tableCell} value={type} />,
    [tableStyles]
  );

  const renderCell = useCallback(
    (data?: string | null | number) => (
      <Typography className={tableStyles.tableCell}>{data}</Typography>
    ),
    [tableStyles]
  );

  const columns: IDataTableColumn<QRCode>[] = useMemo(
    () => [
      {
        title: 'Location',
        valueFactory: ({ location }) => renderCell(location),
      },
      {
        title: 'Name',
        valueFactory: ({ name }) => renderCell(name),
      },
      {
        title: 'QR Code Number',
        valueFactory: qrCode => renderEditLink(qrCode),
      },
      {
        title: 'Type',
        valueFactory: ({ type }) => renderEnumValue(type),
      },
      {
        title: 'Room',
        valueFactory: ({ roomNumber }) => renderCell(roomNumber),
      },
      {
        title: '',
        valueFactory: renderButtons,
      },
    ],
    [renderCell, renderButtons, renderEditLink, renderEnumValue]
  );

  return (
    <>
      {selectedCode && (
        <>
          {editOpen && (
            <EditQRCodeModal
              qrCode={selectedCode}
              propertyId={propertyId}
              rooms={rooms}
              open={editOpen}
              onClose={onClose}
            />
          )}
        </>
      )}
      <DataTable items={qrCodes} columns={columns} getItemKey={code => code.id} loading={loading}>
        <ShowMoreButton isDone={isDone} loadMore={loadMore} />
      </DataTable>
    </>
  );
}
